/* eslint-disable camelcase */
import {
    createValidationDataObject,
    isEmailValid,
    isValueRequiredValid,
} from 'app/utilities/form-validation';

export const PURCHASER_FORM_FIELDS = [
    {
        name: 'first_name',
        label: 'First name',
        placeholder: '',
        type: 'text',
        isRequired: true,
        validator: (first_name) => {
            if (!isValueRequiredValid(first_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'last_name',
        label: 'Last name',
        placeholder: '',
        type: 'text',
        isRequired: true,
        validator: (last_name) => {
            if (!isValueRequiredValid(last_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'phone',
        label: 'Phone number',
        placeholder: '',
        type: 'tel',
        isRequired: true,
        validator: (phone) => {
            if (!isValueRequiredValid(phone)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascripthttps://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
            if (phone.length <= 6) { // eslint-disable-line
                return createValidationDataObject(false, 'Not a valid phone number');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'address',
        label: 'Billing address',
        placeholder: '',
        type: 'text',
        isRequired: true,
        validator: (address) => {
            if (!isValueRequiredValid(address)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'post_code',
        label: 'Post code',
        placeholder: '',
        type: 'text',
        isRequired: true,
        validator: (postCode) => {
            if (!isValueRequiredValid(postCode)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'city',
        label: 'City/Town/Suburb',
        placeholder: '',
        type: 'text',
        isRequired: true,
        validator: (city) => {
            if (!isValueRequiredValid(city)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'country',
        label: 'Country',
        placeholder: 'Select...',
        type: 'select',
        isRequired: true,
        defaultValue: 'nz',
        options: [
            {
                label: 'Select...',
                value: ''
            },
            {
                label: 'Afghanistan',
                value: 'af'
            },
            {
                label: 'Aland Islands',
                value: 'ax'
            },
            {
                label: 'Albania',
                value: 'al'
            },
            {
                label: 'Algeria',
                value: 'dz'
            },
            {
                label: 'American Samoa',
                value: 'as'
            },
            {
                label: 'Andorra',
                value: 'ad'
            },
            {
                label: 'Angola',
                value: 'ao'
            },
            {
                label: 'Anguilla',
                value: 'ai'
            },
            {
                label: 'Antarctica',
                value: 'aq'
            },
            {
                label: 'Antigua and Barbuda',
                value: 'ag'
            },
            {
                label: 'Argentina',
                value: 'ar'
            },
            {
                label: 'Armenia',
                value: 'am'
            },
            {
                label: 'Aruba',
                value: 'aw'
            },
            {
                label: 'Australia',
                value: 'au'
            },
            {
                label: 'Austria',
                value: 'at'
            },
            {
                label: 'Azerbaijan',
                value: 'az'
            },
            {
                label: 'Bahamas',
                value: 'bs'
            },
            {
                label: 'Bahrain',
                value: 'bh'
            },
            {
                label: 'Bangladesh',
                value: 'bd'
            },
            {
                label: 'Barbados',
                value: 'bb'
            },
            {
                label: 'Belarus',
                value: 'by'
            },
            {
                label: 'Belgium',
                value: 'be'
            },
            {
                label: 'Belize',
                value: 'bz'
            },
            {
                label: 'Benin',
                value: 'bj'
            },
            {
                label: 'Bermuda',
                value: 'bm'
            },
            {
                label: 'Bhutan',
                value: 'bt'
            },
            {
                label: 'Bolivia',
                value: 'bo'
            },
            {
                label: 'Bonaire',
                value: 'bq'
            },
            {
                label: 'Bosnia and Herzegovina',
                value: 'ba'
            },
            {
                label: 'Botswana',
                value: 'bw'
            },
            {
                label: 'Bouvet Island',
                value: 'bv'
            },
            {
                label: 'Brazil',
                value: 'br'
            },
            {
                label: 'British Indian Ocean Territory',
                value: 'io'
            },
            {
                label: 'Brunei Darussalam',
                value: 'bn'
            },
            {
                label: 'Bulgaria',
                value: 'bg'
            },
            {
                label: 'Burkina Faso',
                value: 'bf'
            },
            {
                label: 'Burundi',
                value: 'bi'
            },
            {
                label: 'Cambodia',
                value: 'kh'
            },
            {
                label: 'Cameroon',
                value: 'cm'
            },
            {
                label: 'Canada',
                value: 'ca'
            },
            {
                label: 'Cape Verde',
                value: 'cv'
            },
            {
                label: 'Cayman Islands',
                value: 'ky'
            },
            {
                label: 'Central African Republic',
                value: 'cf'
            },
            {
                label: 'Chad',
                value: 'td'
            },
            {
                label: 'Chile',
                value: 'cl'
            },
            {
                label: 'China',
                value: 'cn'
            },
            {
                label: 'Christmas Island',
                value: 'cx'
            },
            {
                label: 'Cocos (Keeling) Islands',
                value: 'cc'
            },
            {
                label: 'Colombia',
                value: 'co'
            },
            {
                label: 'Comoros',
                value: 'km'
            },
            {
                label: 'Congo',
                value: 'cg'
            },
            {
                label: 'Congo, The Democratic Republic Of The',
                value: 'cd'
            },
            {
                label: 'Cook Islands',
                value: 'ck'
            },
            {
                label: 'Costa Rica',
                value: 'cr'
            },
            {
                label: 'Cote D\'ivoire',
                value: 'ci'
            },
            {
                label: 'Croatia',
                value: 'hr'
            },
            {
                label: 'Cuba',
                value: 'cu'
            },
            {
                label: 'Curacao',
                value: 'cw'
            },
            {
                label: 'Cyprus',
                value: 'cy'
            },
            {
                label: 'Czech Republic',
                value: 'cz'
            },
            {
                label: 'Denmark',
                value: 'dk'
            },
            {
                label: 'Djibouti',
                value: 'dj'
            },
            {
                label: 'Dominica',
                value: 'dm'
            },
            {
                label: 'Dominican Republic',
                value: 'do'
            },
            {
                label: 'Ecuador',
                value: 'ec'
            },
            {
                label: 'Egypt',
                value: 'eg'
            },
            {
                label: 'El Salvador',
                value: 'sv'
            },
            {
                label: 'Equatorial Guinea',
                value: 'gq'
            },
            {
                label: 'Eritrea',
                value: 'er'
            },
            {
                label: 'Estonia',
                value: 'ee'
            },
            {
                label: 'Ethiopia',
                value: 'et'
            },
            {
                label: 'Falkland Islands',
                value: 'fk'
            },
            {
                label: 'Faroe Islands',
                value: 'fo'
            },
            {
                label: 'Fiji',
                value: 'fj'
            },
            {
                label: 'Finland',
                value: 'fi'
            },
            {
                label: 'France',
                value: 'fr'
            },
            {
                label: 'French Guiana',
                value: 'gf'
            },
            {
                label: 'French Polynesia',
                value: 'pf'
            },
            {
                label: 'French Southern Territories',
                value: 'tf'
            },
            {
                label: 'Gabon',
                value: 'ga'
            },
            {
                label: 'Gambia',
                value: 'gm'
            },
            {
                label: 'Georgia',
                value: 'ge'
            },
            {
                label: 'Germany',
                value: 'de'
            },
            {
                label: 'Ghana',
                value: 'gh'
            },
            {
                label: 'Gibraltar',
                value: 'gi'
            },
            {
                label: 'Greece',
                value: 'gr'
            },
            {
                label: 'Greenland',
                value: 'gl'
            },
            {
                label: 'Grenada',
                value: 'gd'
            },
            {
                label: 'Guadeloupe',
                value: 'gp'
            },
            {
                label: 'Guam',
                value: 'gu'
            },
            {
                label: 'Guatemala',
                value: 'gt'
            },
            {
                label: 'Guernsey',
                value: 'gg'
            },
            {
                label: 'Guinea',
                value: 'gn'
            },
            {
                label: 'Guinea-Bissau',
                value: 'gw'
            },
            {
                label: 'Guyana',
                value: 'gy'
            },
            {
                label: 'Haiti',
                value: 'ht'
            },
            {
                label: 'Heard Island and Mcdonald Islands',
                value: 'hm'
            },
            {
                label: 'Holy See (Vatican City State)',
                value: 'va'
            },
            {
                label: 'Honduras',
                value: 'hn'
            },
            {
                label: 'Hong Kong',
                value: 'hk'
            },
            {
                label: 'Hungary',
                value: 'hu'
            },
            {
                label: 'Iceland',
                value: 'is'
            },
            {
                label: 'India',
                value: 'in'
            },
            {
                label: 'Indonesia',
                value: 'id'
            },
            {
                label: 'Iran',
                value: 'ir'
            },
            {
                label: 'Iraq',
                value: 'iq'
            },
            {
                label: 'Ireland',
                value: 'ie'
            },
            {
                label: 'Isle Of Man',
                value: 'im'
            },
            {
                label: 'Israel',
                value: 'il'
            },
            {
                label: 'Italy',
                value: 'it'
            },
            {
                label: 'Jamaica',
                value: 'jm'
            },
            {
                label: 'Japan',
                value: 'jp'
            },
            {
                label: 'Jersey',
                value: 'je'
            },
            {
                label: 'Jordan',
                value: 'jo'
            },
            {
                label: 'Kazakhstan',
                value: 'kz'
            },
            {
                label: 'Kenya',
                value: 'ke'
            },
            {
                label: 'Kiribati',
                value: 'ki'
            },
            {
                label: 'Korea, Democratic People\'s Republic Of',
                value: 'kp'
            },
            {
                label: 'Korea, Republic Of',
                value: 'kr'
            },
            {
                label: 'Kuwait',
                value: 'kw'
            },
            {
                label: 'Kyrgyzstan',
                value: 'kg'
            },
            {
                label: 'Lao People\'s Democratic Republic',
                value: 'la'
            },
            {
                label: 'Latvia',
                value: 'lv'
            },
            {
                label: 'Lebanon',
                value: 'lb'
            },
            {
                label: 'Lesotho',
                value: 'ls'
            },
            {
                label: 'Liberia',
                value: 'lr'
            },
            {
                label: 'Libya',
                value: 'ly'
            },
            {
                label: 'Liechtenstein',
                value: 'li'
            },
            {
                label: 'Lithuania',
                value: 'lt'
            },
            {
                label: 'Luxembourg',
                value: 'lu'
            },
            {
                label: 'Macao',
                value: 'mo'
            },
            {
                label: 'Macedonia',
                value: 'mk'
            },
            {
                label: 'Madagascar',
                value: 'mg'
            },
            {
                label: 'Malawi',
                value: 'mw'
            },
            {
                label: 'Malaysia',
                value: 'my'
            },
            {
                label: 'Maldives',
                value: 'mv'
            },
            {
                label: 'Mali',
                value: 'ml'
            },
            {
                label: 'Malta',
                value: 'mt'
            },
            {
                label: 'Marshall Islands',
                value: 'mh'
            },
            {
                label: 'Martinique',
                value: 'mq'
            },
            {
                label: 'Mauritania',
                value: 'mr'
            },
            {
                label: 'Mauritius',
                value: 'mu'
            },
            {
                label: 'Mayotte',
                value: 'yt'
            },
            {
                label: 'Mexico',
                value: 'mx'
            },
            {
                label: 'Micronesia',
                value: 'fm'
            },
            {
                label: 'Moldova',
                value: 'md'
            },
            {
                label: 'Monaco',
                value: 'mc'
            },
            {
                label: 'Mongolia',
                value: 'mn'
            },
            {
                label: 'Montenegro',
                value: 'me'
            },
            {
                label: 'Montserrat',
                value: 'ms'
            },
            {
                label: 'Morocco',
                value: 'ma'
            },
            {
                label: 'Mozambique',
                value: 'mz'
            },
            {
                label: 'Myanmar',
                value: 'mm'
            },
            {
                label: 'Namibia',
                value: 'na'
            },
            {
                label: 'Nauru',
                value: 'nr'
            },
            {
                label: 'Nepal',
                value: 'np'
            },
            {
                label: 'Netherlands',
                value: 'nl'
            },
            {
                label: 'New Caledonia',
                value: 'nc'
            },
            {
                label: 'New Zealand',
                value: 'nz'
            },
            {
                label: 'Nicaragua',
                value: 'ni'
            },
            {
                label: 'Niger',
                value: 'ne'
            },
            {
                label: 'Nigeria',
                value: 'ng'
            },
            {
                label: 'Niue',
                value: 'nu'
            },
            {
                label: 'Norfolk Island',
                value: 'nf'
            },
            {
                label: 'Northern Mariana Islands',
                value: 'mp'
            },
            {
                label: 'Norway',
                value: 'no'
            },
            {
                label: 'Oman',
                value: 'om'
            },
            {
                label: 'Pakistan',
                value: 'pk'
            },
            {
                label: 'Palau',
                value: 'pw'
            },
            {
                label: 'Palestinian Territory',
                value: 'ps'
            },
            {
                label: 'Panama',
                value: 'pa'
            },
            {
                label: 'Papua New Guinea',
                value: 'pg'
            },
            {
                label: 'Paraguay',
                value: 'py'
            },
            {
                label: 'Peru',
                value: 'pe'
            },
            {
                label: 'Philippines',
                value: 'ph'
            },
            {
                label: 'Pitcairn',
                value: 'pn'
            },
            {
                label: 'Poland',
                value: 'pl'
            },
            {
                label: 'Portugal',
                value: 'pt'
            },
            {
                label: 'Puerto Rico',
                value: 'pr'
            },
            {
                label: 'Qatar',
                value: 'qa'
            },
            {
                label: 'Reunion',
                value: 're'
            },
            {
                label: 'Romania',
                value: 'ro'
            },
            {
                label: 'Russian Federation',
                value: 'ru'
            },
            {
                label: 'Rwanda',
                value: 'rw'
            },
            {
                label: 'Saint Barthelemy',
                value: 'bl'
            },
            {
                label: 'Saint Helena',
                value: 'sh'
            },
            {
                label: 'Saint Kitts and Nevis',
                value: 'kn'
            },
            {
                label: 'Saint Lucia',
                value: 'lc'
            },
            {
                label: 'Saint Martin',
                value: 'mf'
            },
            {
                label: 'Saint Pierre and Miquelon',
                value: 'pm'
            },
            {
                label: 'Saint Vincent and The Grenadines',
                value: 'vc'
            },
            {
                label: 'Samoa',
                value: 'ws'
            },
            {
                label: 'San Marino',
                value: 'sm'
            },
            {
                label: 'Sao Tome and Principe',
                value: 'st'
            },
            {
                label: 'Saudi Arabia',
                value: 'sa'
            },
            {
                label: 'Senegal',
                value: 'sn'
            },
            {
                label: 'Serbia',
                value: 'rs'
            },
            {
                label: 'Seychelles',
                value: 'sc'
            },
            {
                label: 'Sierra Leone',
                value: 'sl'
            },
            {
                label: 'Singapore',
                value: 'sg'
            },
            {
                label: 'Sint Maarten',
                value: 'sx'
            },
            {
                label: 'Slovakia',
                value: 'sk'
            },
            {
                label: 'Slovenia',
                value: 'si'
            },
            {
                label: 'Solomon Islands',
                value: 'sb'
            },
            {
                label: 'Somalia',
                value: 'so'
            },
            {
                label: 'South Africa',
                value: 'za'
            },
            {
                label: 'South Georgia and The South Sandwich Islands',
                value: 'gs'
            },
            {
                label: 'South Sudan',
                value: 'ss'
            },
            {
                label: 'Spain',
                value: 'es'
            },
            {
                label: 'Sri Lanka',
                value: 'lk'
            },
            {
                label: 'Sudan',
                value: 'sd'
            },
            {
                label: 'Suriname',
                value: 'sr'
            },
            {
                label: 'Svalbard and Jan Mayen',
                value: 'sj'
            },
            {
                label: 'Swaziland',
                value: 'sz'
            },
            {
                label: 'Sweden',
                value: 'se'
            },
            {
                label: 'Switzerland',
                value: 'ch'
            },
            {
                label: 'Syrian Arab Republic',
                value: 'sy'
            },
            {
                label: 'Taiwan',
                value: 'tw'
            },
            {
                label: 'Tajikistan',
                value: 'tj'
            },
            {
                label: 'Tanzania',
                value: 'tz'
            },
            {
                label: 'Thailand',
                value: 'th'
            },
            {
                label: 'Timor-Leste',
                value: 'tl'
            },
            {
                label: 'Togo',
                value: 'tg'
            },
            {
                label: 'Tokelau',
                value: 'tk'
            },
            {
                label: 'Tonga',
                value: 'to'
            },
            {
                label: 'Trinidad and Tobago',
                value: 'tt'
            },
            {
                label: 'Tunisia',
                value: 'tn'
            },
            {
                label: 'Turkey',
                value: 'tr'
            },
            {
                label: 'Turkmenistan',
                value: 'tm'
            },
            {
                label: 'Turks and Caicos Islands',
                value: 'tc'
            },
            {
                label: 'Tuvalu',
                value: 'tv'
            },
            {
                label: 'Uganda',
                value: 'ug'
            },
            {
                label: 'Ukraine',
                value: 'ua'
            },
            {
                label: 'United Arab Emirates',
                value: 'ae'
            },
            {
                label: 'United Kingdom',
                value: 'gb'
            },
            {
                label: 'United States',
                value: 'us'
            },
            {
                label: 'United States Minor Outlying Islands',
                value: 'um'
            },
            {
                label: 'Uruguay',
                value: 'uy'
            },
            {
                label: 'Uzbekistan',
                value: 'uz'
            },
            {
                label: 'Vanuatu',
                value: 'vu'
            },
            {
                label: 'Venezuela',
                value: 've'
            },
            {
                label: 'Vietnam',
                value: 'vn'
            },
            {
                label: 'Virgin Islands, British',
                value: 'vg'
            },
            {
                label: 'Virgin Islands, U.S.',
                value: 'vi'
            },
            {
                label: 'Wallis and Futuna',
                value: 'wf'
            },
            {
                label: 'Western Sahara',
                value: 'eh'
            },
            {
                label: 'Yemen',
                value: 'ye'
            },
            {
                label: 'Zambia',
                value: 'zm'
            },
            {
                label: 'Zimbabwe',
                value: 'zw'
            }
        ],
        validator: (countryCode) => {
            if (!isValueRequiredValid(countryCode)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'about_us',
        label: 'How did you hear about us?',
        placeholder: 'Please select',
        type: 'select',
        options: [
            {
                label: 'Select...',
                value: ''
            },
            {
                label: 'Internet search',
                value: 'Internet search'
            },
            {
                label: 'Friend',
                value: 'Friend'
            },
            {
                label: 'Social Media',
                value: 'Social Media'
            },
            {
                label: 'Coupon website',
                value: 'Coupon website'
            },
            {
                label: 'Gift card',
                value: 'Gift card'
            },
            {
                label: 'Brochure',
                value: 'Brochure'
            },
            {
                label: 'Signage',
                value: 'Signage'
            },
            {
                label: 'TV',
                value: 'TV'
            },
            {
                label: 'Radio',
                value: 'Radio'
            },
            {
                label: 'Press',
                value: 'Press'
            },
            {
                label: 'Flyer',
                value: 'Flyer'
            },
            {
                label: 'Agent',
                value: 'Agent'
            },
            {
                label: 'Other',
                value: 'Other'
            }
        ],
        validator: () => {
            return createValidationDataObject();
        }
    },
    {
        name: 'marketing_opt_in',
        label: 'I agree to receive promotional material, news and information about the Zoo',
        placeholder: '',
        type: 'checkbox',
        isRequired: false,
        defaultValue: false,
        inputType: 'large',
        validator: () => {
            return createValidationDataObject();
        }
    },
    {
        name: 'privacy_policy',
        label: 'By checking this box you confirm that you agree to our <a href="/terms-and-privacy-policy" target="_blank">Terms & Conditions</a> and have read our <a href="/terms-and-privacy-policy" target="_blank">Privacy Policy</a>',
        placeholder: '',
        type: 'checkbox',
        inputType: 'large',
        defaultValue: false,
        isRequired: true,
        validator: (privacy_policy) => {
            if (!privacy_policy) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    }
];
