import env from './env';
import { NEWS } from 'config/news';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const LIMIT_PER_PAGE = 1000;
export const DATE_FORMAT = 'YYYY-MM-DD';

export const ENDPOINTS = {
    ANIMALS: (slug) => `${API_BASE_URL}/zoo/animals?slug=${encodeURIComponent(slug)}`,
    ANIMALS_ALL: `${API_BASE_URL}/zoo/animals?limit=${LIMIT_PER_PAGE}`,
    ARTICLES: (slug) => `${API_BASE_URL}/zoo/news?slug=${encodeURIComponent(slug)}&include=recent`,
    ARTICLES_ALL: `${API_BASE_URL}/zoo/news?limit=${LIMIT_PER_PAGE}`,
    DONATION: `${API_BASE_URL}/zoo/donations`,
    NEWS: (limit = NEWS.INTRO_LIMIT, includeFeatured = true, page = 1, category = null) => {
        let requestURL = `${API_BASE_URL}/zoo/news?limit=${encodeURIComponent(limit)}&is_published=true`;

        // API is weird so need both of these exceptions
        if (includeFeatured) requestURL += '&include=featured';
        if (!includeFeatured) requestURL += '&featured=false';

        if (category) requestURL += `&category=${encodeURIComponent(category)}`;
        if (page) requestURL += `&page=${encodeURIComponent(page)}`;

        return requestURL;
    },
    NEWS_CATEGORIES: `${API_BASE_URL}/zoo/news-categories`,
    NOTIFICATIONS: `${API_BASE_URL}/zoo/notifications`,
    ENCOUNTERS: `${API_BASE_URL}/zoo/encounters`,
    GLOBAL_FIELDS: `${API_BASE_URL}/zoo/global-fields`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/zoo/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    PAGES_ALL: `${API_BASE_URL}/zoo/pages?limit=${LIMIT_PER_PAGE}`,
    SEARCH: (keywords, page = 1) => `${API_BASE_URL}/zoo/search?keywords=${encodeURIComponent(keywords)}&page=${page}`,
    SUBSCRIBE: 'https://rfal77254.activehosted.com/proc.php',
    TICKETS: `${API_BASE_URL}/zoo/tickets/products`,
    TOKEN: `${API_BASE_URL}/oauth/token`,

    // Zoo portal endpoints
    PORTAL_LOGIN: `${API_BASE_URL}/zoo/portal/login`,
    PORTAL_LOGOUT: `${API_BASE_URL}/zoo/portal/logout`,
    PORTAL_SEND_RESET_PASSWORD_LINK: `${API_BASE_URL}/zoo/portal/password/forgot-password`,
    PORTAL_RESET_PASSWORD: `${API_BASE_URL}/zoo/portal/password/reset`,
    PORTAL_VERIFY_TOKEN: `${API_BASE_URL}/zoo/portal/password/verify-token`,
    PORTAL_USER: `${API_BASE_URL}/zoo/portal/user`,
    PORTAL_USER_REGISTER: `${API_BASE_URL}/zoo/portal/register`,
    PORTAL_DASHBOARD: `${API_BASE_URL}/zoo/portal/dashboard`,
    PORTAL_PASSHOLDERS: `${API_BASE_URL}/zoo/portal/passholders`,
    UPLOAD_PASSHOLDER_PHOTO: (id) => `${API_BASE_URL}/zoo/portal/passholders/${id}/upload-photo`,

    // Buy membership
    BUY_MEMBERSHIP_PRODUCTS: `${API_BASE_URL}/zoo/portal/annual-pass/products`,
    BUY_MEMBERSHIP_CALCULATE_PRICES: `${API_BASE_URL}/zoo/portal/annual-pass/price`,
    BUY_MEMBERSHIP_CREATE_BOOKING: `${API_BASE_URL}/zoo/portal/annual-pass/purchase`,
    BUY_MEMBERSHIP_PAYMENT: `${API_BASE_URL}/windcave/notification`,
    REDEEM_GA_DISCOUNT: `${API_BASE_URL}/zoo/portal/check-discount-code`,

    // Buy gift pass
    GIFT_MEMBERSHIP_CREATE_BOOKING: `${API_BASE_URL}/zoo/portal/annual-pass/gift`,
    GIFT_MEMBERSHIP_CODE: (code) => `${API_BASE_URL}/zoo/portal/gift/${encodeURIComponent(code)}`,
    REDEEM_GIFT: (code) => `${API_BASE_URL}/zoo/portal/redeem-gift/${encodeURIComponent(code)}`,
    SITEMAP: `${API_BASE_URL}/zoo/sitemap`,
    CUSTOM_URLS: `${API_BASE_URL}/zoo/custom-urls`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500
};

export const ERROR_MESSAGE = {
    DEFAULT: 'Something went wrong, please try again later',
    INTERNAL_SERVER_ERROR: 'We\'ve encountered an unexpected error. Please try again later or get in touch with us at <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a>. We apologise for any inconvenience.'
};

export const TIMEOUT = 0;
