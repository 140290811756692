/* eslint-disable camelcase */
import * as giftStepDefs from 'config/gift-memberships-steps-defs';
import { clientFetch } from 'app/utilities/fetch';
import { CREATE_ACCOUNT_FORM_FIELDS } from 'config/buy-memberships-create-account';
import { createFormFieldsDetail } from 'config/buy-memberships-passholder-details';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PURCHASER_FORM_FIELDS } from 'config/buy-memberships-purchaser';
import { setInternalErrorAction } from './status';
// import sendEcommerceDataToGoogleAnalytics from 'app/utilities/send-ecommerce-data-to-google-analytics';
import { combineEpics, ofType } from 'redux-observable';

import {
    COMPLETE_STEP_INDEX,
    CUSTOMISE_STEP_INDEX,
    REVIEW_STEP_INDEX
} from 'config/buy-memberships-steps';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import { EVENT_ADD_TO_CART, fireCartEvent, firePurchaseCompletedEvent, formatMembershipProducts, sanitizeSelectedProducts } from 'app/utilities/buy-memberships';
import { GIFTER_FORM_FIELDS, RECEIVER_FORM_FIELDS } from 'config/gift-memberships-forms';

export const INITIAL_STATE = {
    currentStepIndex: 1,
    productsSelected: [],
    passHolders: [],
    primaryContact: CREATE_ACCOUNT_FORM_FIELDS.reduce((acc, { name }) => {
        acc[name] = {
            value: '',
            touched: false
        };

        return acc;
    }, {}),
    purchaser: PURCHASER_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
        acc[name] = {
            value: defaultValue === undefined ? '' : defaultValue,
            touched: false
        };

        return acc;
    }, {}),
    paymentStatus: undefined,
    bookingUpdated: false,
    totalAmount: undefined,
    renewPassId: undefined,
    renewDiscountPct: 0,
    hasRenewDiscount: false,

    // Fields which only pertain to gifting
    gifter: GIFTER_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
        acc[name] = {
            value: defaultValue === undefined ? '' : defaultValue,
            touched: false
        };

        return acc;
    }, {}),
    giftReceiver: RECEIVER_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
        acc[name] = {
            value: defaultValue === undefined ? '' : defaultValue,
            touched: false
        };

        return acc;
    }, {}),
    extraDiscount: null
};


// Actions
export const SET_CURRENT_STEP_INDEX = 'rfa-zoo-website/buy-memberships/SET_CURRENT_STEP_INDEX';
export const SET_PRODUCT_SELECTED = 'rfa-zoo-website/buy-memberships/SET_PRODUCT_SELECTED';
export const SET_PASS_DETAILS_FIELD_VALUE = 'rfa-zoo-website/buy-memberships/SET_PASS_DETAILS_FIELD_VALUE';
export const TOUCHED_PASS_DETAILS_FIELD = 'rfa-zoo-website/buy-memberships/TOUCHED_PASS_DETAILS_FIELD';
export const SET_PRIMARY_CONTACT_FIELD_VALUE = 'rfa-zoo-website/buy-memberships/SET_PRIMARY_CONTACT_FIELD_VALUE';
export const TOUCHED_PRIMARY_CONTACT_FIELD = 'rfa-zoo-website/buy-memberships/TOUCHED_PRIMARY_CONTACT_FIELD';
export const SET_PURCHASER_FIELD_VALUE = 'rfa-zoo-website/buy-memberships/SET_PURCHASER_FIELD_VALUE';
export const TOUCHED_PURCHASER_FIELD = 'rfa-zoo-website/buy-memberships/TOUCHED_PURCHASER_FIELD';
export const SET_GIFTER_FIELD_VALUE = 'rfa-zoo-website/buy-memberships/SET_GIFTER_FIELD_VALUE';
export const TOUCHED_GIFTER_FIELD = 'rfa-zoo-website/buy-memberships/TOUCHED_GIFTER_FIELD';
export const SET_GIFT_RECEIVER_FIELD_VALUE = 'rfa-zoo-website/buy-memberships/SET_GIFT_RECEIVER_FIELD_VALUE';
export const TOUCHED_GIFT_RECEIVER_FIELD = 'rfa-zoo-website/buy-memberships/TOUCHED_GIFT_RECEIVER_FIELD';
export const SUBMIT_BOOKING = 'rfa-zoo-website/buy-memberships/SUBMIT_BOOKING';
export const SUBMIT_GIFT_PURCHASE = 'rfa-zoo-website/buy-memberships/SUBMIT_GIFT_PURCHASE';
export const INITIATE_UPDATE_BOOKING = 'rfa-zoo-website/buy-memberships/INITIATE_UPDATE_BOOKING';
export const INITIATE_UPDATE_GIFT_BOOKING = 'rfa-zoo-website/buy-memberships/INITIATE_UPDATE_GIFT_BOOKING';
export const SET_ORDER_NUMBER = 'rfa-zoo-website/buy-memberships/SET_ORDER_NUMBER';
export const SET_PAYMENT_STATUS = 'rfa-zoo-website/buy-memberships/SET_PAYMENT_STATUS';
export const CLEAR_PAYMENT_STATUS = 'rfa-zoo-website/buy-memberships/CLEAR_PAYMENT_STATUS';
export const SEND_ECOMMERCE_DATA_TO_GOOGLE_ANALYTICS = 'rfa-zoo-website/buy-memberships/SEND_ECOMMERCE_DATA_TO_GOOGLE_ANALYTICS';
export const BOOKING_UPDATED_SUCCESSFULLY = 'rfa-zoo-website/buy-memberships/BOOKING_UPDATED_SUCCESSFULLY';
export const PRE_SELECTED_PRODUCTS = 'rfa-zoo-website/buy-memberships/PRE_SELECTED_PRODUCTS';
export const RESET_ORDER = 'rfa-zoo-website/buy-memberships/RESET_ORDER';
export const RESET_GIFT_ORDER = 'rfa-zoo-website/buy-memberships/RESET_GIFT_ORDER';
export const SET_RENEW_PASS = 'rfa-zoo-website/buy-memberships/SET_RENEW_PASS';
export const INITIATE_RENEW_PASS = 'rfa-zoo-website/buy-memberships/INITIATE_RENEW_PASS';
export const RESET_BUY_MEMBERSHIPS_FLOW = 'rfa-zoo-website/buy-memberships/RESET_BUY_MEMBERSHIPS_FLOW';
export const SET_EXTRA_DISCOUNT = 'rfa-zoo-website/buy-memberships/SET_EXTRA_DISCOUNT';

// Action Creators
export const setCurrentStepIndexAction = (index) => ({
    type: SET_CURRENT_STEP_INDEX,
    payload: index
});

export const setProductsSelectedAction = (productsToSelect) => ({
    type: SET_PRODUCT_SELECTED,
    payload: {
        productsToSelect
    }
});

export const setPassHoldersFieldValueAction = (product, passIndexByProduct, fieldName, value) => ({
    type: SET_PASS_DETAILS_FIELD_VALUE,
    payload: {
        product,
        passIndexByProduct,
        fieldName,
        value
    }
});

export const touchedPassHoldersFieldAction = (product, passIndexByProduct, fieldName) => ({
    type: TOUCHED_PASS_DETAILS_FIELD,
    payload: {
        product,
        passIndexByProduct,
        fieldName
    }
});

export const setPrimaryContactFieldValueAction = (fieldName, value) => ({
    type: SET_PRIMARY_CONTACT_FIELD_VALUE,
    payload: {
        fieldName,
        value
    }
});

export const touchedPrimaryContactFieldAction = (fieldName) => ({
    type: TOUCHED_PRIMARY_CONTACT_FIELD,
    payload: {
        fieldName
    }
});

export const setPurchaserFieldValieAction = (fieldName, value) => ({
    type: SET_PURCHASER_FIELD_VALUE,
    payload: {
        fieldName,
        value
    }
});

export const touchedPurchaserFieldAction = (fieldName) => ({
    type: TOUCHED_PURCHASER_FIELD,
    payload: {
        fieldName
    }
});

export const setGifterFieldValueAction = (fieldName, value) => ({
    type: SET_GIFTER_FIELD_VALUE,
    payload: {
        fieldName,
        value
    }
});

export const touchedGifterFieldAction = (fieldName) => ({
    type: TOUCHED_GIFTER_FIELD,
    payload: {
        fieldName
    }
});

export const setGiftReceiverFieldValueAction = (fieldName, value) => ({
    type: SET_GIFT_RECEIVER_FIELD_VALUE,
    payload: {
        fieldName,
        value
    }
});

export const touchedGiftReceiverFieldAction = (fieldName) => ({
    type: TOUCHED_GIFT_RECEIVER_FIELD,
    payload: {
        fieldName
    }
});

export const submitBookingAction = (data, setIsSubmitting, setStatus) => ({
    type: SUBMIT_BOOKING,
    payload: {
        data,
        setIsSubmitting,
        setStatus
    }
});

export const submitGiftPurchaseAction = (data, setIsSubmitting, setStatus) => ({
    type: SUBMIT_GIFT_PURCHASE,
    payload: {
        data,
        setIsSubmitting,
        setStatus
    }
});

export const initiateUpdateBookingAction = (result, products) => ({
    type: INITIATE_UPDATE_BOOKING,
    payload: {
        result,
        products
    }
});

export const initiateUpdateGiftBookingAction = (result, products) => ({
    type: INITIATE_UPDATE_GIFT_BOOKING,
    payload: {
        result,
        products
    }
});

export const setPaymentStatusAction = (success, message, renewPassId, receiptInfo, successStep, failStep) => ({
    type: SET_PAYMENT_STATUS,
    payload: {
        success,
        message,
        renewPassId,
        receiptInfo,
        successStep,
        failStep
    }
});

export const clearPaymentStatusAction = () => ({
    type: CLEAR_PAYMENT_STATUS
});

export const sendEcommerceDataToGoogleAnalyticsAction = () => ({
    type: SEND_ECOMMERCE_DATA_TO_GOOGLE_ANALYTICS
});

export const bookingUpdatedSuccessfullyAction = () => ({
    type: BOOKING_UPDATED_SUCCESSFULLY
});

export const preSelectedPassAction = (products) => ({
    type: PRE_SELECTED_PRODUCTS,
    payload: {
        products
    }
});

export const resetOrderAction = (order, products) => ({
    type: RESET_ORDER,
    payload: {
        order,
        products
    }
});

export const resetGiftOrderAction = (order, products) => ({
    type: RESET_GIFT_ORDER,
    payload: {
        order,
        products
    }
});

export const setRenewPassAction = (renewPassId, renewDiscountPct) => ({
    type: SET_RENEW_PASS,
    payload: {
        renewPassId,
        renewDiscountPct
    }
});

export const initiateRenewPassAction = (pass, prefill, products) => ({
    type: INITIATE_RENEW_PASS,
    payload: {
        pass,
        prefill,
        products
    }
});

export const resetBuyMembershipsFlowAction = () => ({
    type: RESET_BUY_MEMBERSHIPS_FLOW
});

export const setExtraDiscountAction = (type, discount, code, account) => ({
    type: SET_EXTRA_DISCOUNT,
    payload: {
        type,
        discount,
        code,
        account
    }
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    const { payload, type } = action;

    switch (type) {
        case SET_CURRENT_STEP_INDEX:
            return { ...state, currentStepIndex: payload };
        case SET_PRODUCT_SELECTED:
            return setProductsSelected(state, payload);
        case SET_PASS_DETAILS_FIELD_VALUE:
            return setPassHoldersFieldValue(state, payload);
        case TOUCHED_PASS_DETAILS_FIELD:
            return touchedFormField(state, payload);
        case SET_PRIMARY_CONTACT_FIELD_VALUE:
            return setPrimaryContactFieldValue(state, payload);
        case TOUCHED_PRIMARY_CONTACT_FIELD:
            return touchedPrimaryContactField(state, payload);
        case SET_PURCHASER_FIELD_VALUE:
            return setPurchaserDetailsFieldValue(state, payload);
        case TOUCHED_PURCHASER_FIELD:
            return touchedPurchaserDetailsField(state, payload);
        case SET_GIFTER_FIELD_VALUE:
            return setGifterFieldValue(state, payload);
        case TOUCHED_GIFTER_FIELD:
            return touchedGifterField(state, payload);
        case SET_GIFT_RECEIVER_FIELD_VALUE:
            return setGiftReceiverFieldValue(state, payload);
        case TOUCHED_GIFT_RECEIVER_FIELD:
            return touchedGiftReceiverField(state, payload);
        case SET_PAYMENT_STATUS:
            return setPaymentStatus(state, payload);
        case CLEAR_PAYMENT_STATUS:
            return clearPaymentStatus(state);
        case BOOKING_UPDATED_SUCCESSFULLY:
            return bookingUpdatedSuccessfully(state);
        case RESET_ORDER:
            return resetOrder(state, payload);
        case RESET_GIFT_ORDER:
            return resetGiftOrder(state, payload);
        case PRE_SELECTED_PRODUCTS:
            return preSelectPass(state, payload);
        case SET_RENEW_PASS:
            return setRenewPass(state, payload);
        case RESET_BUY_MEMBERSHIPS_FLOW:
            return { ...INITIAL_STATE };
        case SET_EXTRA_DISCOUNT:
            return setExtraDiscount(state, payload);
        default:
            return state;
    }
};

// matching product name to pre-select product.
const preSelectPass = (state, { products }) => {
    const params = new URLSearchParams(window.location.search);
    const productsToSelect = [];
    formatMembershipProducts(products).map((product) => {
        const productName = product.name.toLowerCase().replace(/\s/g, '');
        const quantities = parseInt(params.get(productName)) || 0;
        if (quantities > 0) {
            productsToSelect.push({ ...product, quantities: product.is_guest ? 1 : quantities });
        }
    });

    const updatedProductsSelected =  updateSelectedData([], productsToSelect);

    const normalProductsSelected = updatedProductsSelected.filter(
        ({ is_cub: isCub, is_guest: isGuest }) => !isGuest && !isCub);

    if (!normalProductsSelected || normalProductsSelected.length === 0) {
        return {
            ...state,
            productsSelected: [],
            passHolders: []
        };
    }

    return {
        ...state,
        productsSelected : updatedProductsSelected,
        passHolders: generatePassHolders(updatedProductsSelected)
    };
};

const setProductsSelected = (state, { productsToSelect }) => {
    const updatedProductsSelected = updateSelectedData(state.productsSelected, productsToSelect);
    const sanitizedProducts = sanitizeSelectedProducts(updatedProductsSelected);

    return {
        ...state,
        productsSelected: sanitizedProducts,
        passHolders: generatePassHolders(sanitizedProducts),
        extraDiscount: null
    };
};

const setPassHoldersFieldValue = (state, { product, passIndexByProduct, fieldName, value }) => {
    return {
        ...state,
        passHolders: state.passHolders.map((pass) => {
            if (pass.product === product && pass.passIndexByProduct === passIndexByProduct) {
                return {
                    ...pass,
                    formFields: pass.formFields.map((field) => {
                        if (field.fieldName === fieldName) {
                            return {
                                ...field,
                                value,
                                // fix date picker not triggering the blur event on the input field if we click on the icon instead of the field
                                touched: true
                            };
                        }

                        return field;
                    })
                };
            }

            return pass;
        })
    };
};

const touchedFormField = (state, { product, passIndexByProduct, fieldName }) => {
    return {
        ...state,
        passHolders: state.passHolders.map((pass) => {
            if (pass.product === product && pass.passIndexByProduct === passIndexByProduct) {
                return {
                    ...pass,
                    formFields: pass.formFields.map((field) => {
                        if (field.fieldName === fieldName) {
                            return {
                                ...field,
                                touched: true
                            };
                        }

                        return field;
                    })
                };
            }

            return pass;
        })
    };
};

const setPurchaserDetailsFieldValue = (state, { fieldName, value }) => {
    return {
        ...state,
        purchaser: {
            ...state.purchaser,
            [fieldName]: {
                ...state.purchaser[fieldName],
                value
            }
        }
    };
};

const touchedPurchaserDetailsField = (state, { fieldName }) => {
    return {
        ...state,
        purchaser: {
            ...state.purchaser,
            [fieldName]: {
                ...state.purchaser[fieldName],
                touched: true
            }
        }
    };
};

const setPrimaryContactFieldValue = (state, { fieldName, value }) => {
    return {
        ...state,
        primaryContact: {
            ...state.primaryContact,
            [fieldName]: {
                ...state.primaryContact[fieldName],
                value
            }
        }
    };
};

const touchedPrimaryContactField = (state, { fieldName }) => {
    return {
        ...state,
        primaryContact: {
            ...state.primaryContact,
            [fieldName]: {
                ...state.primaryContact[fieldName],
                touched: true
            }
        }
    };
};

const setGifterFieldValue = (state, { fieldName, value }) => {
    return {
        ...state,
        gifter: {
            ...state.gifter,
            [fieldName]: {
                ...state.gifter[fieldName],
                value
            }
        }
    };
};

const touchedGifterField = (state, { fieldName }) => {
    return {
        ...state,
        gifter: {
            ...state.gifter,
            [fieldName]: {
                ...state.gifter[fieldName],
                touched: true
            }
        }
    };
};

const setGiftReceiverFieldValue = (state, { fieldName, value }) => {
    return {
        ...state,
        giftReceiver: {
            ...state.giftReceiver,
            [fieldName]: {
                ...state.giftReceiver[fieldName],
                value
            }
        }
    };
};

const touchedGiftReceiverField = (state, { fieldName }) => {
    return {
        ...state,
        giftReceiver: {
            ...state.giftReceiver,
            [fieldName]: {
                ...state.giftReceiver[fieldName],
                touched: true
            }
        }
    };
};

const setPaymentStatus = (state, payload) => {
    // Go go complete step or review order again
    const currentStepIndex = payload.success ? payload.successStep : payload.failStep;

    return {
        ...state,
        currentStepIndex,
        paymentStatus: payload
    };
};

const clearPaymentStatus = (state) => {
    return {
        ...state,
        paymentStatus: undefined
    };
};

const setRenewPass = (state, { renewPassId, renewDiscountPct }) => {
    return {
        ...state,
        renewPassId,
        hasRenewDiscount: renewDiscountPct > 0,
        renewDiscountPct
    };
};

const bookingUpdatedSuccessfully = (state) => {
    return {
        ...state,
        bookingUpdated: true
    };
};

const resetOrder = (state, { order, products }) => {
    if (!order) {
        return {
            ...state,
            productsSelected: INITIAL_STATE.productsSelected,
            passHolders: INITIAL_STATE.passHolders,
            purchaser: INITIAL_STATE.purchaser,
            totalAmount: INITIAL_STATE.totalAmount
        };
    }

    const { purchaser, pass_holders, total_amount, has_guest_pass, requires_caregiver } = order;
    const formattedProducts = formatMembershipProducts(products);
    const sortedPassHolders = sortPassHoldersByProduct(pass_holders, formattedProducts);
    const productsToSelect = generateProductsToSelectByPassHolders(sortedPassHolders, formattedProducts);

    if (has_guest_pass) {
        productsToSelect.push({
            ...formattedProducts.find(({ name }) => name.toLowerCase() === 'adult guest'), quantities: 1
        });
    }

    if (requires_caregiver) {
        productsToSelect.push({
            ...formattedProducts.find(({ name }) => name.toLowerCase() === 'support person'), quantities: 1
        });
    }

    const updatedProductsSelected = updateSelectedData(state.selectedProducts, productsToSelect);

    return {
        ...state,
        productsSelected: updatedProductsSelected,
        passHolders:
            generatePassHolders(updatedProductsSelected)
                .map((pass, index) => {
                    const updatedFormFields = pass.formFields.map((field) => (
                        { ...field, value: sortedPassHolders[index][field.fieldName] || '' }
                    ));

                    return { ...pass, formFields: updatedFormFields };
                }),
        purchaser: PURCHASER_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
            acc[name] = {
                value: purchaser[name] || defaultValue || '',
                touched: false
            };

            return acc;
        }, {}),
        totalAmount: total_amount
    };
};

export const resetGiftOrder = (state, { order, products }) => {
    const productsToSelect = Object.entries(order.product_qtys).map(([productType, qty]) => {
        return {
            ...products.find(({ name }) => name === productType),
            quantities: qty
        };
    });

    if (order.has_guest_pass) {
        productsToSelect.push({
            ...products.find(({ name }) => name.toLowerCase() === 'adult guest'), quantities: 1
        });
    }

    return {
        ...setProductsSelected(state, { productsToSelect }),
        gifter: GIFTER_FORM_FIELDS.reduce((acc, { name }) => {
            acc[name] = {
                value: order[name],
                touched: false
            };

            return acc;
        }, {}),
        giftReceiver: RECEIVER_FORM_FIELDS.reduce((acc, { name }) => {
            acc[name] = {
                value: order[name],
                touched: false
            };

            return acc;
        }, {}),
        purchaser: PURCHASER_FORM_FIELDS.reduce((acc, { name, defaultValue }) => {
            acc[name] = {
                value: order.purchaser[name] || defaultValue || '',
                touched: false
            };

            return acc;
        }, {}),
    };
};

export const setExtraDiscount = (state, { type, discount, code, account }) => {
    if (!type) {
        return {
            ...state,
            extraDiscount: null
        };
    }

    if (!code) {
        return {
            ...state,
            extraDiscount:
                Object.keys(state.extraDiscount)
                    .filter((key) => key !== type)
                    .reduce((obj, key) => {
                        obj[key] = state.extraDiscount[key];

                        return obj;
                    }, {})
        };
    }

    return {
        ...state,
        extraDiscount: {
            ...state.extraDiscount,
            [type]: {
                discount,
                code,
                account
            }
        }
    };
};

// Helpers
export const updateSelectedData = (selectedProducts, productsToSelect) => {
    const productsToAdd = productsToSelect.filter(({ quantities }) => quantities > 0);
    const productsToSelectIndex = productsToSelect.map(({ product_index }) => product_index);

    return [
        ...selectedProducts && selectedProducts.length ?
            selectedProducts.filter(({ product_index }) => !productsToSelectIndex.includes(product_index)) : [],
        ...productsToAdd.map(({ name, ...rest }) => ({ ...rest, product: name }))
    ].sort((a, b) => a.product_index - b.product_index);
};

export const generatePassHolders = (productsSelected) => {
    return productsSelected.reduce((prev, { product, quantities, is_cub, is_child, is_guest }) => {
        if (!is_guest) {
            const passHoldersByPerson = new Array(quantities).fill().map((item, index) => ({
                product,
                passIndexByProduct: index,
                isPrimaryContactQualified: !is_cub && !is_child,
                formFields: createFormFieldsDetail(product).map(({ name: fieldName, defaultValue }) => ({
                    fieldName,
                    value: defaultValue,
                    touched: false
                }))
            }));
            prev.push(...passHoldersByPerson);
        }

        return prev;
    }, []);
};

const sortPassHoldersByProduct = (passHolders, products) => {
    return passHolders.sort((pass, passNext) => {
        const productAIndex = products.find(({ name }) => name === pass.product).product_index;
        const productBIndex = products.find(({ name }) => name === passNext.product).product_index;

        return productAIndex - productBIndex;
    });
};

const generateProductsToSelectByPassHolders = (passHoldersData, products) => {
    const productsToAddObject = passHoldersData.reduce((acc, { product }) => {
        if (acc[product]) {
            acc[product]++;
        } else {
            acc[product] = 1;
        }

        return acc;
    }, {});

    const productsToAddArray = Object.keys(productsToAddObject).map((key) => {
        const productToAdd = products.find(({ name }) => name === key);

        return { ...productToAdd, quantities: productsToAddObject[key] };
    });

    return productsToAddArray;
};

const redirectToPaymentExpress = (url) => {
    window.location.href = url;
};

const failedToUpdateBooking = () => {
    window.alert('Failed to perform the operation. Try again later.');
    window.location.href = '/';
};

// Epic creator
/**
 * News Epic
 * @param  {func} endpoint               - User login endpoint
 * @param  {func} userLoginSuccessActionSequence     - Success action creator
 * @param  {func} userLoginFailActionSequence        - Fail action creator
 * @return {func}
 */
export const createSubmitBookingEpic = (endpoint, action) => {
    return (action$) => action$.pipe(
        ofType(action),
        mergeMap(({ payload: { data, setIsSubmitting, setStatus } }) => {
            return (
                clientFetch(endpoint, {
                    method: 'POST',
                    body: JSON.stringify(data)
                }, true)
                    .then((response) => {
                        const { payment_url } = response;

                        if (payment_url) return redirectToPaymentExpress(payment_url);

                        return;
                    })
                    .catch((err) => {
                        const { error: { status, error } } = err;
                        const errorMessage =
                            status === RESPONSE.BAD_REQUEST &&
                            error &&
                            error.message ||
                            ERROR_MESSAGE.DEFAULT;

                        setStatus({ error: { message:  errorMessage, code: error.code } });
                    })
                    .finally(() => setIsSubmitting(false))
            );
        })
    );
};

export const createInitiateUpdateBookingEpic = (updateBookingEndpoint, bookingUpdatedSuccessfullyAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_UPDATE_BOOKING),
        mergeMap(({ payload: { result, products } }) => {
            if (!result) {
                return [setCurrentStepIndexAction(CUSTOMISE_STEP_INDEX)];
            }

            return (
                clientFetch(updateBookingEndpoint, {
                    method: 'POST',
                    body: JSON.stringify({ result })
                })
                    .then((response) => {
                        const { success, message, order, txn_id } = response;
                        const { renew_pass_id, renew_discount_pct } = order;

                        const actions = [
                            resetOrderAction(order, products),
                            setPaymentStatusAction(success, message, renew_pass_id, order.receipt_info, COMPLETE_STEP_INDEX, REVIEW_STEP_INDEX),
                            bookingUpdatedSuccessfullyAction()
                        ];

                        if (renew_pass_id) {
                            actions.push(setRenewPassAction(renew_pass_id, renew_discount_pct));
                        }

                        // If successful, push confirmed booking to data layer
                        if (success) {
                            firePurchaseCompletedEvent(order, txn_id);
                        }

                        return actions;
                    })
                    .catch(() => {
                        return failedToUpdateBooking();
                    })
            );
        })
    );
};

export const createInitiateUpdateGiftBookingEpic = (updateBookingEndpoint, bookingUpdatedSuccessfullyAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_UPDATE_GIFT_BOOKING),
        mergeMap(({ payload: { result, products } }) => {
            if (!result) {
                return [setCurrentStepIndexAction(CUSTOMISE_STEP_INDEX)];
            }

            return (
                clientFetch(updateBookingEndpoint, {
                    method: 'POST',
                    body: JSON.stringify({ result })
                })
                    .then((response) => {
                        const { success, message, order, txn_id } = response;
                        const { renew_pass_id } = order;

                        const actions = [
                            resetGiftOrderAction(order, products),
                            setPaymentStatusAction(success, message, renew_pass_id, order.receipt_info, giftStepDefs.COMPLETE_STEP_INDEX, giftStepDefs.REVIEW_STEP_INDEX),
                            bookingUpdatedSuccessfullyAction()
                        ];

                        // If successful, push confirmed booking to data layer
                        if (success) {
                            firePurchaseCompletedEvent(order, txn_id);
                        }

                        return actions;
                    })
                    .catch(() => {
                        return failedToUpdateBooking();
                    })
            );
        })
    );
};

export const createSendEcommerceDataToGoogleAnalyticsEpic = () => {
    return (action$, state$) => action$.pipe(
        ofType(SEND_ECOMMERCE_DATA_TO_GOOGLE_ANALYTICS),
        mergeMap(() => {
            // sendEcommerceDataToGoogleAnalytics(state$.value);

            return EMPTY;
        })
    );
};

// Send analytics for pre selected products
export const createPreSelectProductsEpic = () => {
    return (action$, state$) => action$.pipe(
        ofType(PRE_SELECTED_PRODUCTS),
        mergeMap(() => {
            fireCartEvent(EVENT_ADD_TO_CART, state$.value.buyMemberships.productsSelected);

            return EMPTY;
        })
    );
};

export const createInitiateRenewPassEpic = () => {
    return (action$) => action$.pipe(
        ofType(INITIATE_RENEW_PASS),
        mergeMap(({ payload: { pass, prefill, products } }) => {
            // Fetch passholders if needed to prefill details
            if (prefill) {
                const endpoint = `${ENDPOINTS.PORTAL_PASSHOLDERS}?annual_pass_ids[]=${pass.id}`;

                return (
                    clientFetch(endpoint, { method: 'GET' }, true)
                        .then((response) => {
                            const passHolders = response[pass.id];

                            // Normally shouldn't happen
                            // If the passholders API is cached, then this could happen
                            if (!passHolders) {
                                return [
                                    setInternalErrorAction({
                                        message: ERROR_MESSAGE.INTERNAL_SERVER_ERROR
                                    })
                                ];
                            }

                            // Flag if anybody requires a caregiver
                            const requiresCaregiver = passHolders.reduce((acc, { requires_caregiver }) => {
                                return requires_caregiver || acc;
                            }, false);

                            // Reset the order with pass holder details prefilled
                            const order = {
                                purchaser: {},
                                // Filter out unnamed passholders (guests)
                                pass_holders: passHolders.filter(({ is_guest }) => !is_guest),
                                total_amount: 0,
                                has_guest_pass: pass.has_guest_pass,
                                requires_caregiver: requiresCaregiver,
                            };

                            return [
                                resetOrderAction(order, products),
                                setCurrentStepIndexAction(CUSTOMISE_STEP_INDEX),
                                setRenewPassAction(pass.id, pass.renew_discount_pct)
                            ];
                        })
                );
            }

            // No need to prefill, just set renew pass ID
            return [resetOrderAction(), setRenewPassAction(pass.id, pass.renew_discount_pct)];
        })
    );
};

const sendEcommerceDataToGoogleAnalyticsEpic = createSendEcommerceDataToGoogleAnalyticsEpic();

const submitBookingEpic = createSubmitBookingEpic(ENDPOINTS.BUY_MEMBERSHIP_CREATE_BOOKING, SUBMIT_BOOKING);
const submitGiftPurchaseEpic = createSubmitBookingEpic(ENDPOINTS.GIFT_MEMBERSHIP_CREATE_BOOKING, SUBMIT_GIFT_PURCHASE);

const initiateUpdateBookingEpic = createInitiateUpdateBookingEpic(
    ENDPOINTS.BUY_MEMBERSHIP_PAYMENT,
    bookingUpdatedSuccessfullyAction,
);

const initiateUpdateGiftBookingEpic = createInitiateUpdateGiftBookingEpic(
    ENDPOINTS.BUY_MEMBERSHIP_PAYMENT,
    bookingUpdatedSuccessfullyAction,
);

const initiateRenewPassEpic = createInitiateRenewPassEpic();

const preSelectProductsEpic = createPreSelectProductsEpic();

export const epics = combineEpics(submitBookingEpic, submitGiftPurchaseEpic, initiateUpdateBookingEpic, initiateUpdateGiftBookingEpic, sendEcommerceDataToGoogleAnalyticsEpic, initiateRenewPassEpic, preSelectProductsEpic);
