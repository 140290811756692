import { AC_RECAPTCHA_KEY } from 'config/recaptcha';
import { createValidationDataObject } from 'app/utilities/form-validation';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import Modal from 'app/components/partials/modal';
import newsletterFormFields from 'app/utilities/newsletter-form-fields';
import PropTypes from 'prop-types';
import React from 'react';
import Recaptcha from 'react-recaptcha';

class NewsletterSignup extends React.Component {
    // Handlers
    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const { submitForm } = this.props;
        submitForm(formData);
    }

    handlePreSubmit(event) {
        event.preventDefault();
        this.props.openSignupModal();
    }

    handleVerify(response) {
        if (response) {
            this.props.formSuccessfullyVerified();
        }
    }

    // Renders
    renderField({ name, placeholder, type, validator }) {
        const { getFieldTouched, getFieldValue, setFieldValue, touchedField } = this.props;

        const touched = getFieldTouched(name);
        const value = getFieldValue(name);
        const validationData = touched ? validator(value) : createValidationDataObject();

        return (
            <div className="newsletter-field" key={name}>
                <input
                    name={name}
                    type={type}
                    className={`newsletter-input ${validationData.valid ? '' : 'is-invalid'}`}
                    value={value}
                    onChange={(event) => {
                        setFieldValue(name, event.target.value);
                        touchedField(name);
                    }}
                    placeholder={placeholder}
                    onBlur={() => touchedField(name)}
                />
                {!validationData.valid && (
                    <p className="newsletter-validation">{validationData.message}</p>
                )}
            </div>
        );
    }

    renderSubmitSection() {
        const { isBusy, submitted, submittedSuccessfully } = this.props;

        if (isBusy) {
            return (
                <button type="button" className="button small">
                    <Loader type="small" />
                </button>
            );
        }

        if (submitted) {
            if (submittedSuccessfully) {
                return (
                    <button type="submit" className="button small">
                        Sent
                    </button>
                );
            }
        }

        return this.renderSubmitButton();
    }

    renderSubmitButton() {
        let formInvalid = false;
        const invalid = newsletterFormFields.some(({ name, validator }) => {
            return !validator(this.props.getFieldValue(name)).valid;
        });

        if (this.props.isSignupModalOpen) {
            formInvalid = invalid || !this.props.reCaptchaVerifiedSuccessfully;
        } else {
            formInvalid = invalid;
        }

        return (
            <button
                type="submit"
                className="button small"
                disabled={formInvalid}
            >Sign up</button>
        );
    }

    renderSignupForm(mode) {
        return (
            <form
                className={`newsletter ${mode === 'modal' ? 'newsletter-modal' : ''}`}
                role="group"
                action="#"
                method="POST"
                onSubmit={mode === 'modal' ? this.handleSubmit.bind(this) : this.handlePreSubmit.bind(this)}
            >
                <input type="hidden" name="u" value="3" />
                <input type="hidden" name="f" value="3" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />

                {newsletterFormFields.map(this.renderField.bind(this))}
                {this.props.error && <p className="newsletter-validation error">Sorry, we didn’t get your message. Try again later.</p>}
                {mode === 'modal' && (
                    <div>
                        <Recaptcha sitekey={AC_RECAPTCHA_KEY} verifyCallback={this.handleVerify.bind(this)} expiredCallback={this.props.failedToVerifyForm} />
                        <p className="terms-and-privacy">We manage subscriber name and email details in accordance with our
                            <Link to="/terms-and-privacy-policy" target="_blank">
                                Privacy Policy
                            </Link>
                        </p>
                    </div>
                )}
                {this.renderSubmitSection()}
            </form>
        );
    }

    renderModal() {
        const hideModal = () => this.props.resetForm();
        let isVisible = false,
            message = '',
            title = '';

        if (this.props.isSignupModalOpen && !this.props.submitted) {
            isVisible = true;
            title = 'Sign up to our newsletter';

            return (
                <Modal isVisible={isVisible} onCloseHandler={() => hideModal()}>
                    <div>
                        <h3>{title}</h3>
                        {this.renderSignupForm('modal')}
                    </div>
                </Modal>
            );
        }

        if (this.props.submitted && this.props.submittedSuccessfully) {
            isVisible = true;
            title = 'Thanks for signing up';
            message = 'We’ll be sharing the latest news from the Zoo. You can change your email preferences at any time by following the link at the bottom of our newsletters.';
        }

        if (this.props.error) {
            isVisible = true;
            title = 'Oops! Something went wrong';
            message = 'Please try again later.';
        }

        return (
            <Modal isVisible={isVisible} onCloseHandler={() => hideModal()}>
                <div>
                    <h3>{title}</h3>
                    <p>{message}</p>
                    <button
                        className="button primary"
                        type="button"
                        onClick={() => hideModal()}
                    >OK</button>
                </div>
            </Modal>
        );
    }

    render() {
        // submitted, submittedSuccessfully
        return (
            <div>
                {this.renderSignupForm()}
                {this.renderModal()}
                <p className="terms-and-privacy">We manage subscriber name and email details in accordance with our
                    <Link to="/terms-and-privacy-policy">
                        Privacy Policy
                    </Link>
                </p>
            </div>
        );
    }
}

NewsletterSignup.propTypes = {
    error: PropTypes.bool.isRequired,
    failedToVerifyForm: PropTypes.func.isRequired,
    formSuccessfullyVerified: PropTypes.func.isRequired,
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    reCaptchaVerifiedSuccessfully: PropTypes.bool,
    isSignupModalOpen: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool,
    touchedField: PropTypes.func.isRequired,
    openSignupModal: PropTypes.func.isRequired
};

export default NewsletterSignup;
