import Banner from 'app/components/partials/banner';
import { dataLayerPush } from 'app/utilities/gtm';
import Helmet from 'react-helmet';
import Loader from 'app/components/partials/loader';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import ProductsInfoBanner from '../partials/buy-memberships/products-info-banner';
import ProgressBar from 'app/components/partials/buy-memberships/progress-bar';
import PropTypes from 'prop-types';
import {
    BUY_MEMBERSHIP_STEPS,
    MEMBER_DETAILS_STEP_INDEX,
    REVIEW_STEP_INDEX
} from 'config/buy-memberships-steps';
import { canPurchaseTicket, getAnnualPass, getCosts } from 'app/utilities/buy-memberships';
import {
    initiateRenewPassAction,
    initiateUpdateBookingAction,
    preSelectedPassAction,
    setCurrentStepIndexAction,
    setExtraDiscountAction,
    setRenewPassAction
} from 'app/ducks/buy-memberships';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

const BuyMembershipPage = ({ isRenewPass = false }) => {
    const {
        currentStepIndex,
        productsSelected,
        bookingUpdated,
        renewPassId,
        renewDiscountPct
    } = useSelector((state) => state.buyMemberships);
    const { products, discounts } = useSelector((state) => state.tickets.membership) || { products: [], discounts: [] };
    const { extraDiscount } = useSelector((state) => state.buyMemberships);
    const user = useSelector((state) => state.auth.user);
    const canBuyMemberShip = canPurchaseTicket(user);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const currentStep = BUY_MEMBERSHIP_STEPS.find((step) => step.index === currentStepIndex);
    const CurrentStepComponent = currentStep.component;

    const { totalCosts, saveCosts, costsAfterDiscount } = getCosts(productsSelected, discounts, renewDiscountPct, extraDiscount);

    const renewPassIdParam = searchParams.get('renewPassId');

    // eslint-disable-next-line no-magic-numbers
    const displayInfoBanner =
        currentStepIndex === MEMBER_DETAILS_STEP_INDEX || currentStepIndex === REVIEW_STEP_INDEX;

    const stepClickHandler = (index) => dispatch(setCurrentStepIndexAction(index));

    const extractResultFromUrl = () => searchParams.get('result');

    const pushDataToGa = () => {
        /* ↓ Tracking buy membership tracking events */
        dataLayerPush({
            id: 'micro_conversion',
            event: 'membershipTracking',
            action: isRenewPass ? 'Renew' : 'Purchase',
            label: currentStep.name
        });
        /* ↑ Tracking buy membership tracking events */
    };

    useEffect(() => {
        // User won't be redirected when they are renewing or on complete step
        if (isRenewPass || extractResultFromUrl()) return;

        // if user not allow to purchase new tickets
        // user has login and has one or more passs
        if (!canBuyMemberShip) {
            navigate(MEMBERS_PORTAL_ROUTES.DASHBOARD);
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);

        pushDataToGa();
    }, [currentStepIndex]);

    useEffect(() => {
        const result = extractResultFromUrl();
        dispatch(initiateUpdateBookingAction(result, products));

        if (result) return;

        // Renew membership flow
        if (isRenewPass) {
            // User must be logged in to renew an annual pass
            const pass = getAnnualPass(user.annual_passes, renewPassIdParam);

            if (renewPassIdParam && pass && pass.can_renew) {
                const prefill = searchParams.get('prefill');
                dispatch(initiateRenewPassAction(pass, prefill, products));
            } else {
                // If no renewPassIdParam or no pass can be renewed, redirect user to portal renew page
                navigate(MEMBERS_PORTAL_ROUTES.RENEW, { replace: true });
            }
        } else {
            // pre selected products only for new pass purchase
            dispatch(preSelectedPassAction(products));
        }

        // Clear the renew pass store and clear the extra discount when user leaves the page
        return () => {
            dispatch(setRenewPassAction(null, 0));
            dispatch(setExtraDiscountAction(null));
        };
    }, []);

    if (extractResultFromUrl() && !bookingUpdated) {
        return (
            <div className="page || page-view">
                <div className="loader-container pad">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <main role="main" className="main">
            <Helmet>
                <title>Buy membership</title>
            </Helmet>
            <div className="page || page-view">
                <Banner title={currentStep.title} subtitle={currentStep.subtitle} />

                <ProgressBar
                    activeStepIndex={currentStepIndex}
                    items={BUY_MEMBERSHIP_STEPS}
                    stepClickHandler={stepClickHandler}
                />
                <section className="constrain-width large || block || buy-membership-block">
                    {displayInfoBanner &&
                        <ProductsInfoBanner
                            stepClickHandler={stepClickHandler}
                            costsAfterDiscount={costsAfterDiscount}
                        />
                    }
                    {/* wait for products, and also wait for renew details to be fetched if required  */}
                    {products && products.length !== 0 && (!renewPassIdParam || renewPassId) && (
                        <div className="content">
                            <CurrentStepComponent
                                saveCosts={saveCosts}
                                totalCosts={totalCosts}
                                costsAfterDiscount={costsAfterDiscount}
                                stepClickHandler={stepClickHandler}
                            />
                        </div>
                    )}
                </section>
            </div>
        </main>
    );
};

BuyMembershipPage.propTypes = {
    isRenewPass: PropTypes.bool
};

export default BuyMembershipPage;
