/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import EnquiryForm from './newsletter-signup';
import {
    failedToVerifyFormAction,
    formSuccessfullyVerifiedAction,
    openSignupModalAction,
    resetFormAction,
    setFormFieldValueAction,
    submitFormAction,
    touchedFormFieldAction
} from 'app/ducks/newsletter-signup';
import {
    selectError,
    selectFieldTouched,
    selectFieldValue,
    selectIsBusy,
    selectIsSignupModalOpen,
    selectReCaptchaVerifiedSuccessfully,
    selectSubmitted,
    selectSubmittedSuccessfully,
} from 'app/selectors/newsletter-signup';

function mapStateToProps(state) {
    return {
        getFieldTouched: (fieldName) => selectFieldTouched(state, fieldName),
        getFieldValue: (fieldName) => selectFieldValue(state, fieldName),
        isSignupModalOpen: selectIsSignupModalOpen(state),
        reCaptchaVerifiedSuccessfully: selectReCaptchaVerifiedSuccessfully(state),
        submitted: selectSubmitted(state),
        isBusy: selectIsBusy(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state),
        error: selectError(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        failedToVerifyForm: () => dispatch(failedToVerifyFormAction()),
        formSuccessfullyVerified: () => dispatch(formSuccessfullyVerifiedAction()),
        resetForm: () => dispatch(resetFormAction()),
        setFieldValue: (fieldName, value) => dispatch(setFormFieldValueAction(fieldName, value)),
        touchedField: (fieldName) => dispatch(touchedFormFieldAction(fieldName)),
        openSignupModal: () => dispatch(openSignupModalAction()),
        submitForm: (formData) => dispatch(submitFormAction(formData))
    };
}

const VisibleEnquiryForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnquiryForm);

export default VisibleEnquiryForm;
